<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>Burak Güngör.</h2>
                    <h3>Software Engineer</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
              <a (click)="downloadCV()" class="main-btn">
                {{"Header.cvBtn" | translate}}
              </a>
            </div>
        </div>
    </div>
</section>
